import * as client_hooks from '../../src/hooks.client.ts';

export { matchers } from './client-matchers.js';

export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65')];

export const server_loads = [2,8,9,10];

export const dictionary = {
	"/(default)/(public)": [~19,[2,6],[3,7]],
	"/(default)/(public)/@[username]": [20,[2,6,8],[3,7]],
	"/(default)/(public)/@[username]/campaigns": [~21,[2,6,8],[3,7]],
	"/(default)/(public)/@[username]/courses": [~22,[2,6,8],[3,7]],
	"/(default)/(public)/@[username]/events": [~23,[2,6,8],[3,7]],
	"/(default)/(public)/@[username]/new": [~24,[2,6,8],[3,7]],
	"/(default)/(public)/@[username]/shop": [~25,[2,6,8],[3,7]],
	"/(default)/(public)/_old_edit/[id]": [~28,[2,6],[3,7]],
	"/(default)/(user-settings)/account": [51,[2,9],[3]],
	"/(default)/(minimal)/(payment)/add-payment-method": [~15,[2,5],[3]],
	"/admin": [~61,[10],[11]],
	"/admin/pricing-algo": [62,[10],[11]],
	"/api/og/demand": [~63],
	"/api/og/product": [~64],
	"/api/og/user": [~65],
	"/(default)/(user-settings)/billing": [~52,[2,9],[3]],
	"/(default)/(minimal)/(payment)/complete-payment": [~16,[2,5],[3]],
	"/(default)/(public)/contact": [~29,[2,6],[3,7]],
	"/(default)/(public)/courses": [~33,[2,6],[3,7]],
	"/(default)/(public)/course": [~30,[2,6],[3,7]],
	"/(default)/(public)/course/[slug]": [~31,[2,6],[3,7]],
	"/(default)/(public)/course/[slug]/[lessonnr]": [~32,[2,6],[3,7]],
	"/(default)/(user-settings)/dashboard": [~53,[2,9],[3]],
	"/(default)/(public)/demand/[id]": [~34,[2,6],[3,7]],
	"/(default)/(public)/discover": [~35,[2,6],[3,7]],
	"/(default)/(public)/download": [~36,[2,6],[3,7]],
	"/(default)/(public)/download/[slug]": [~37,[2,6],[3,7]],
	"/(default)/(public)/funding": [~38,[2,6],[3,7]],
	"/(default)/(public)/goro": [~39,[2,6],[3,7]],
	"/(default)/(public)/k": [~40,[2,6],[3,7]],
	"/(default)/(public)/k/[slug]": [~41,[2,6],[3,7]],
	"/(default)/(public)/k/[slug]/pledge-successful": [~43,[2,6],[3,7]],
	"/(default)/(public)/k/[slug]/purchase-successful": [~44,[2,6],[3,7]],
	"/(default)/(public)/k/[slug]/[lessonnr]": [~42,[2,6],[3,7]],
	"/(default)/(public)/legal": [~45,[2,6],[3,7]],
	"/(default)/(minimal)/(auth)/login": [~12,[2,4],[3]],
	"/(default)/(user-settings)/messages": [54,[2,9],[3]],
	"/(default)/(public)/monetize-demands": [~46,[2,6],[3,7]],
	"/(default)/(public)/new": [~47,[2,6],[3,7]],
	"/(default)/(user-settings)/notifications": [55,[2,9],[3]],
	"/(default)/(user-settings)/notifications/unsubscribe": [~56,[2,9],[3]],
	"/(default)/(user-settings)/orders": [~57,[2,9],[3]],
	"/(default)/(minimal)/(payment)/payment-setup-complete": [~17,[2,5],[3]],
	"/(default)/(user-settings)/preferences": [58,[2,9],[3]],
	"/(default)/(public)/pricing": [~48,[2,6],[3,7]],
	"/(default)/(public)/privacy": [~49,[2,6],[3,7]],
	"/(default)/(minimal)/(auth)/profile-setup": [~13,[2,4],[3]],
	"/(default)/(user-settings)/profile": [~59,[2,9],[3]],
	"/(default)/(minimal)/(auth)/register": [~14,[2,4],[3]],
	"/(default)/(user-settings)/stats": [60,[2,9],[3]],
	"/(default)/(public)/terms": [~50,[2,6],[3,7]],
	"/(default)/(minimal)/(payment)/test-payment-method": [~18,[2,5],[3]],
	"/(default)/(public)/[createOrEdit=createOrEdit]/[[productId]]": [~27,[2,6],[3,7]],
	"/(default)/(public)/[...path]": [~26,[2,6],[3,7]]
};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};