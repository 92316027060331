import type { HandleClientError } from "@sveltejs/kit";
import { PUBLIC_KNDKTM_ENV } from "$env/static/public";
import { BrowserTracing } from "@sentry/tracing";
import { getId } from "./lib/utils";
import { env } from "$env/dynamic/public";
import * as Sentry from "@sentry/svelte";

// Initialize the Sentry SDK only on prod.
Sentry.init({
	enabled: ["prod", "preview"].includes(PUBLIC_KNDKTM_ENV),
	dsn: env.PUBLIC_SENTRY_DSN,
	attachStacktrace: true,
	integrations: [new BrowserTracing()],

	// release: `${__CF_PAGES_BRANCH__}@${__CF_PAGES_COMMIT_SHA__}`,
	release: env.PUBLIC_GITHUB_SHA || "local",
	environment: PUBLIC_KNDKTM_ENV,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production.
	// For now let's leave it at 100% and scale it down as we have more traffic.
	tracesSampleRate: 1.0,
});

// @TODO(can): type this globally somehow
window.Sentry = Sentry;

export const handleError: HandleClientError = ({ error, event }) => {
	// example integration with https://sentry.io/
	const errorId = getId();
	Sentry.captureException(error, { event, errorId });
	console.log(error, { event, errorId });

	return {
		message: "Whoops!",
		// @TODO(can): shouldn't complain thanks to the interface in `/app.d.ts`
		code: error?.code ?? "UNKNOWN",
		errorId,
	};
};
